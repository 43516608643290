import React from 'react';  
import styled from 'styled-components';  
  
const Header = styled.h1`  
  color: #004c97;  
`;  
  
const Tokenomics = () => {  
  return (  
    <div>  
      <br />  
      <Header>Tokenomics</Header>
      <br />   
      <p>Token Symbol: NEKO
      <br />
      <br />
      Tax: 0/0
      <br />
      Total Supply: 1B
      <br />
      <br />
      Freeze Authority Revoked
      <br />
      Mint Authority Revoked
      <br />
      <br />   
      (Launched on Pump.fun)
      <br />   
      <br />   
      <br />   
      <br />   
      <br />   
      <br />   
      <br />   
      </p>  
      We hope that as many holders as possible will support the Nekomata community.<br /><br />
      The amount of Nekomata Coin held by its developers has been kept at a minimum level just enough to establish credibility with the holders as fellow community participants.
      
      <br />These coins are intended to be an investment to support the liquidity of the leading company when mass production of Catgirl Robots begins.
      <br /><br />Until the Catgirl Robot becomes a reality!
      
      <br />   
      
    </div>  
  );  
};  
  
export default Tokenomics;  