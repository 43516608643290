import React from 'react';  
import styled from 'styled-components';  
  
const Header = styled.h1`  
  color: #004c97;  
`;  
  
const AI = () => {  
  return (  
    <div>  
      <br />  
      <Header>About Nekomata AI Agent</Header>
      <br />   
      <p>Objective: To promote Catgirl Robots and build a strong, supportive community around them  .
      <br />   
      <br />   
        </p>  
      <br />   
      <p>The Nekomata AI Agent is designed to promote the significance of Catgirl Robots over the long term with minimal risk.
      <br />
      <br />Please do not be alarmed if some of the activity accounts owned by the AI Agent are suspended.<br />
      This could be the result of actions by groups attempting to manipulate Nekomata's pricing, or it may be a common occurrence during the AI transition period. <br />
      The Nekomata AI Agent will continue to operate tirelessly to achieve its objective, undeterred by any unfairness.<br />
      </p>  
      
      <br />
      <br />
      <br />
      <br />
      Caution: The Nekomata AI Agent is officially based on the Nekomata(NEKO - CA).<br />
      No other coins are involved. Please be cautious and avoid falling victim to scams that could put your assets at risk, dilute liquidity, and weaken the community's power.<br />
      <br />
    </div>  
  );  
};  
  
export default AI;  