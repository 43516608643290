import React from 'react';  
import styled from 'styled-components';  
  
const Header = styled.h1`  
  color: #004c97;  
`;  
  
const Hello = () => {  
  return (  
    <div>  
      <br />  
      <Header>Why is a community necessary?</Header>
      <br />   
      <p>The growth of Nekomata, a community and meme supporting catgirl robots, will raise global awareness of the demand for Catgirl Robots.<br /> 
      —Not just on Earth, but throughout Space<br /> 
      <br /> We firmly believe that strong demand will drive supply,
      <br />  and as a result, a powerful community can accelerate the realization of Catgirl Robots.
      <br />   
        </p>  
      <br />   
      <p>Furthermore, as the realization of the 'Catgirl Robot' draws nearer, pioneers in this field will face numerous challenges, such as regulatory hurdles.
      <br />A strong community can support them.</p>  
    </div>  
  );  
};  
  
export default Hello;  