import React, { useState } from 'react';  
import './App.css';  
import './ImageGallery.css'; 

const ImageGallery = ({ images }) => {  
  const [startIndex, setStartIndex] = useState(0);  
  const imagesPerPage = 5;  
  
  const handleNext = () => {  
    if (startIndex + imagesPerPage < images.length) {  
      setStartIndex(startIndex + imagesPerPage);  
    }  
  };  
  
  const handlePrev = () => {  
    if (startIndex - imagesPerPage >= 0) {  
      setStartIndex(startIndex - imagesPerPage);  
    }  
  };  
  
  const currentImages = images.slice(startIndex, startIndex + imagesPerPage);  
  
  return (  
    <div className="gallery-container">  
      <button onClick={handlePrev} disabled={startIndex === 0}>  
      {'<<<'}  
      </button>  
      <div className="image-list">  
        {currentImages.map((image, index) => (  
          <img key={index} src={image} alt={`img-${index}`} />  
        ))}  
      </div>  
      <button onClick={handleNext} disabled={startIndex + imagesPerPage >= images.length}>  
      {'>>>'}
      </button>  
    </div>  
  );  
};  
  

const VimeoEmbed = () => {  
  const containerStyle = {  
    width: '60%', 
    margin: '0 auto',  
    position: 'relative',  
    paddingTop: '42.1875%',
    
  };  
  
  const iframeStyle = {  
    position: 'absolute',  
    top: 0,  
    left: 0,  
    width: '100%',  
    height: '100%',
  };  
  
  return (  
    <div style={containerStyle}>  
      <iframe  
        src="https://player.castr.com/live_525fd320b60811ef8f0e3fcb84ef4d27"  
        frameBorder="0"  
        scrolling="no"
        allow="autoplay; fullscreen; picture-in-picture; webkitallowfullscreen; allowfullscreen; mozallowfullscreen; oallowfullscreen; msallowfullscreen"  
        allowFullScreen  
        style={iframeStyle}  
      ></iframe>  
    </div>  
  );  
};  

const CopyToClipboard = () => {  
    const textToCopy = "Ef8vPwTriSNNcGivkagE5qwnMDYtbjCsjWXjBQeypump";  
    
    const handleCopy = () => {  
      navigator.clipboard.writeText(textToCopy)  
        .then(() => {  
          alert('The text has been copied to the clipboard!');  
        })  
        .catch(err => {  
          console.error('copy failed.', err);  
        });  
    };  
    
    return (  
    <div>  
    <span   
        onClick={handleCopy}   
        style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
    >  
        {"CA(pump.fun): " + textToCopy} {}  
    </span>  
    </div>  
);  
};  

const AlertSoonYoutube = () => {  
    
  const handleCopy = () => {  
    alert('This begins once Nekomata is firmly established.\nBeware of copycats and support Nekomata.');  
  };  
  
  return (  
  <div>  
  <span   
      onClick={handleCopy}   
      style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
  >  
      {"Nekomata AI Agent: Youtube"} {}  
  </span>  
  </div>  
);  
};  
const AlertSoonTiktok = () => {  
    
    const handleCopy = () => {  
      alert('This begins once Nekomata is firmly established.\nBeware of copycats and support Nekomata.');  
    };  
    
    return (  
    <div>  
    <span   
        onClick={handleCopy}   
        style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
    >  
        {"Nekomata AI Agent: Tiktok"} {}  
    </span>  
    </div>  
);  
};  

function Home() {  
  const images = [  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_016n.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_056n.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_078.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_094.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_158e.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_007.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_008.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_009.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_010.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_011n.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_012n.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_013.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_002.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_003.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_004.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_005.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_006.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_015.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_016n.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_017.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_018.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_019.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_020.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_021n.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_022n.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_026n.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_027.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_028.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_029.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_031n.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_032n.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_033n.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_034.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_035n.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_036.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_037.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_038.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_039n.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_040n.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_041n.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_042.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_043.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_044.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_045.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_046.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_047.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_048.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_049.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_050n.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_051.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_052n.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_053.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_054.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_055.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_056n.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_057.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_058.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_059.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_060.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_061.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_065n.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_066.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_067.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_068.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_070.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_071.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_072.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_073.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_074.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_075.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_076.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_077.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_078.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_079.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_080.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_081.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_082.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_083.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_084.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_085.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_086.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_087n.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_088.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_089.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_090.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_091.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_092.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_093.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_094.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_095.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_096.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_097.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_098.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_099n.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_100.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_111e.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_112e.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_113.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_114.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_115.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_116e.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_117.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_118e.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_119e.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_120.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_121.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_122e.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_123.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_124.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_125.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_126.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_127e.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_128e.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_129.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_130.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_131.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_132e.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_133.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_134.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_135.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_136.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_137.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_138.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_139n.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_140n.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_143.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_144.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_146.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_147.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_149.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_150.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_151.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_152.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_153.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_154.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_155.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_156.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_157.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_158e.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_159m.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_160.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_161n.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_163.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_164n.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_167.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_168.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_169.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_170.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_171.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_172.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_173.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_174.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_176.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_177.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_178.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_179.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_180.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_183.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_184.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_185.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_187.png',  
 'https://pub-a86a4e0e22464dcab5b4fe1020bc41f8.r2.dev/resized_190.png',
  ];  

  return (  
    <div className="App">  
      <div className="content">  
      
        <p className="centered-text">  
          <b>  
            <font size="6">Nekomata</font>  
            <font size="2"><br />
            Nekomata is a compound word derived from the Japanese word for Cat, ネコ (Neko), and 'Automata', a term for mechanical beings, which is the origin of the word 'robot'.<br />
            It also refers to a legendary cat from Japanese folklore.<br /><br />

            Project Nekomata is a social meme coin projecba on an AI Agent.<br />
            It supports the realization of Catgirl Robots, fueled by a community enthusiastic about Catgirl Robots.<br />
          'Nekomata' will become the new nickname representing Catgirl Robots.
            <br /><br />
            </font>
            <br /> The dream of the Catgirl Robot will come true.<br />
            <br />  
           
          </b>  
        </p>  
        <br />  
        <p>  
        <font size="6">CC0 designs for the Nekomata promote.</font>   
          <ImageGallery images={images} />  
        </p>  
        <br />  
        <p>  
          <VimeoEmbed /> 
        </p>  
        <hr />  
        <div className="table-container">  
          <center>  
            <table border="0" cellspacing="0" width="90%">  
              <tbody>  
                <tr>  
                  <td height="57" valign="top" width="55%">  
                    <ul>  
                      <li><a href="hello">Why is a community necessary?</a></li>  
                    </ul>  
                  </td>  
                  <td height="47" valign="top" width="55%">  
                    <ul>  
                      <li><a href="https://pump.fun/coin/Ef8vPwTriSNNcGivkagE5qwnMDYtbjCsjWXjBQeypump">Buy on pump.fun</a></li>  
                    </ul>  
                  </td>  
                </tr>  
                <tr>  
                  <td height="47" valign="top" width="55%">  
                    <ul>  
                      <li><a href="reason">Why does it have to be a Catgirl?</a></li>  
                    </ul>  
                  </td>  
                  <td height="47" valign="top" width="55%">  
                    <ul>  
                      <li>  
                        <li><CopyToClipboard /> </li>
                      </li>  
                    </ul>  
                  </td>  
                </tr>  
                <tr>  
                  <td height="47" valign="top" width="55%">  
                    <ul>    
                      <li><a href="ai">About Nekomata AI Agent</a></li>  
                    </ul>  
                  </td>  
                  <td height="57" valign="top" width="55%">  
                    <ul>  
                      <li><a href="tokenomics">Tokenomics</a></li>  
                    </ul>  
                  </td>  
                </tr>  
                <tr>  
                  <td height="47" valign="top" width="55%">  
                    <ul>    
                      {/* <li><a href="http://youtube.com">Nekomata AI Agent: Youtube</a></li>   */}
                      
                      <li><a href="http://x.com/nekomataaiagent">Nekomata AI Agent: X</a></li>  
                    </ul>  
                  </td>  
                  <td height="57" valign="top" width="55%">  
                    <ul>    
                      <li><a href="http://youtube.com/@nekomataio">Nekomata AI Agent: Youtube</a></li>  
                    </ul>  
                  </td>  
                </tr>  
                <tr>  
                  <td height="47" valign="top" width="55%">  
                    <ul>    
                      {/* <li><a href="http://tiktok.com">Nekomata AI Agent: Tiktok</a></li>   */}
                      
                      <AlertSoonTiktok />
                    </ul>  
                  </td>  
                  <td height="57" valign="top" width="55%">  
                    <ul>  
                      <li><a href="soon">Coming Soon</a></li>  
                    </ul>  
                  </td>  
                </tr>  
                
              </tbody>  
            </table>  
          </center>  
        </div>  
        <hr />  
      </div>  
      
      <small>  
      <p>  
        <br></br>
      </p> 
      The Nekomata AI Agent is currently in its beta version and conducts live streaming sessions intermittently.<br />
      Are you curious about the next steps to promote the Catgirl Robot? If so, support Nekomata.<br /><br />
      Caution: Please verify the contract address accurately.

      <p>  
      </p>  
      </small>  
      <footer className="footer">  
 
        <p>  
          © 2024 <b>Nekomata</b>  
        </p>  
      </footer>  
    </div>  
  );  
}  
  
export default Home;  