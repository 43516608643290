import React from 'react';  
import styled from 'styled-components';  
  
const Header = styled.h1`  
  color: #004c97;  
`;  
  
const NotFound = () => {  
  return (  
    <div>  
      <Header>404 Page</Header>  
      <p>The page you are looking for does not exist.</p>  
    </div>  
  );  
};  
  
export default NotFound;  