import React from 'react';  
import styled from 'styled-components';  
  
const Header = styled.h1`  
  color: #004c97;  
`;  
  
const Reason = () => {  
  return (  
    <div>  
      <br />  
      <Header>Why does it have to be a Catgirl?</Header>
      <br />   
      <p>No explanation is necessary regarding the design aspect.
      <br />If anyone would understand without needing an explanation, it would be you.
      <br />   
      <br />   
      
        </p>  
      <br />   
      <p>Let's explain the functional benefits of the cat ears and dual tails, which are characteristic features of the catgirl, 'Nekomata'.
      <br />
      <br />
      1. The cat ears on the head function as solar panels, effectively capturing solar energy.
      <br />
      2. The two tails act as cables for charging stations, one for home use and the other for outdoor use.
      <br />
      <br />
      <br />This design is not only visually appealing but also highly practical.
      <br />  </p>  
    </div>  
  );  
};  
  
export default Reason;  