import React from 'react';  
import styled from 'styled-components';  
  
const Header = styled.h1`  
  color: #004c97;  
`;  
  
const Ai = () => {  
  return (  
    <div>  
      <br />  
      <Header>Coming Soon</Header>
      <br />   
      <p>Utilizable NFT.<br />1Q 2025 - 2Q 2025</p>
      <br />   
    </div>  
  );  
};  
  
export default Ai;  